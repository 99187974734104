import { useState, useCallback, useMemo } from "react";
import {
  Button,
  Empty,
  notification,
  Table,
  Input,
  Space,
  Tag,
  Popconfirm,
  Row,
  Col,
  Card,
  Divider,
  Tooltip,
  Collapse,
  Modal,
} from "antd";
import axios from "axios";
import {
  DeleteOutlined,
  SearchOutlined,
  FileSyncOutlined,
  LineChartOutlined,
  PlusOutlined,
  DollarOutlined,
  HistoryOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";

import { handleError, uppercaseFirst } from "../../helpers";
import ClusterUsersModal from "../Modal/ClusterUsersModal";
import ClusterUsageModal from "../Modal/ClusterUsageModal";
import ClusterHistoryModal from "../Modal/ClusterHistoryModal";

import {
  SERVICE_LEVEL,
  DEDICATED_SIZE_COLORS as SIZE_COLORS,
} from "../../constants";
import ClusterDeleteModal from "../Modal/ClusterDeleteModal";

const { Column } = Table;
const { Panel } = Collapse;

const API_KEY = "2e148ac7-89c1-4c51-a422-b12881a009fa";

const INSTANCE_TYPES = ["cpu", "gpu"];

const ClustersPane = ({
  clusters,
  host_clusters,
  users,
  usage,
  assignments,
  create,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [clusterId, setClusterId] = useState(null);
  const [showClusterUsersModal, setShowClusterUsersModal] = useState(false);
  const [showClusterUsageModal, setShowClusterUsageModal] = useState(false);
  const [showClusterHistoryModal, setShowClusterHistoryModal] = useState(false);

  const [deleteCluster, setDeleteCluster] = useState(null);

  const handleClusterUsersClose = () => {
    setShowClusterUsersModal(false);
    setClusterId(null);
  };

  const handleClusterHistoryClose = () => {
    setShowClusterHistoryModal(false);
    setClusterId(null);
  };

  const handleClusterUsageClose = () => {
    setShowClusterUsageModal(false);
    setClusterId(null);
  };

  const handleBackupCluster = (cluster) => async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/${cluster.id}/backup`,
        {},
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Cluster Backup",
        description: "Cluster is being backed up.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const openClusterDeleteModal = (cluster) => (_) => {
    setDeleteCluster(cluster);
  };

  const handleUsersClick = (id) => () => {
    setClusterId(id);
    setShowClusterUsersModal(true);
  };

  const handleGrafanaClick = (cluster) => () => {
    window.open(
      `https://kinetica.grafana.net/a/grafana-k8s-app/navigation/cluster/${cluster.cluster_name}?var-datasource=grafanacloud-kinetica-prom&var-loki=grafanacloud-kinetica-logs&refresh=1m&fro`
    );
  };

  const handleKubeConfigClick = (id) => async (_) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/${id}/kube_config`,
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      if (data) {
        Modal.info({
          icon: null,
          content: data?.cluster?.kube_config,
          centered: true,
          width: 900,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleHistoryClick = (id) => () => {
    setClusterId(id);
    setShowClusterHistoryModal(true);
  };

  const handleUsageClick = (id) => () => {
    setClusterId(id);
    setShowClusterUsageModal(true);
  };

  const onSearch = (evt) => {
    setSearchTerm(evt.target.value);
  };

  const filteredClusters = useCallback(
    (service_level = "", status) => {
      return clusters
        .filter((cluster) => {
          return (
            ((status && cluster.status === status) ||
              (!status && cluster.status !== "deleted")) &&
            (cluster.cluster_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
              cluster.hostname
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) &&
            (service_level === "" || cluster.service_level === service_level)
          );
        })
        .sort((a, b) => {
          if (a.created > b.created) return -1;
          if (a.created < b.created) return 1;
          return 0;
        });
    },
    [searchTerm, clusters]
  );

  const buildTable = (label, clusters) => {
    return (
      <Table
        className={`${label.toLowerCase()}-clusters`}
        dataSource={clusters}
        rowKey="id"
        size="small"
        rowClassName={(record) => {
          return record.owner_id ? "used" : "free";
        }}
        pagination={false}
      >
        <Column
          title="Date"
          dataIndex="created"
          key="created"
          width={1}
          render={(created) => {
            return (
              <div
                style={{
                  fontSize: "11px",
                  lineHeight: "12px",
                  width: 55,
                }}
              >
                {format(new Date(created.seconds * 1000), "MM/dd/yy")}
                <br />
                {format(new Date(created.seconds * 1000), "hh:mm aaa")}
              </div>
            );
          }}
        />
        <Column
          title={label}
          dataIndex="cluster_name"
          key="cluster_name"
          render={(cluster_name, record) => {
            const owner = users.find((user) => user.id === record.owner_id);
            return (
              <div style={{ lineHeight: "12px" }}>
                <a href={record.workbench_url} target="_blank" rel="noreferrer">
                  {cluster_name}
                </a>
                <br />
                {owner ? (
                  <label style={{ fontSize: "11px", color: "#999999" }}>
                    {owner.email_addr.toLowerCase()}
                  </label>
                ) : null}
              </div>
            );
          }}
        />
        <Column
          title="Version"
          dataIndex="current_version"
          key="current_version"
          width={1}
          render={(current_version, record) => {
            const { upgrade_info } = record;

            let upgradeAvailable = false;
            let upgradeVersions = [];

            if (upgrade_info && upgrade_info.upgradeAvailable) {
              upgradeAvailable = upgrade_info.upgradeAvailable;
            }
            if (upgrade_info && upgrade_info.upgradeVersions) {
              upgradeVersions = upgrade_info.upgradeVersions;
            }

            return (
              <div
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                }}
              >
                {current_version || "Unknown"}
                <br />
                <Tooltip
                  title={
                    upgradeAvailable
                      ? JSON.stringify(
                          upgradeVersions.map((version) => version.tag)
                        )
                      : "N/A"
                  }
                  placement="right"
                >
                  <Tag
                    color={upgradeAvailable ? "#A5D6A7" : "#eeeeee"}
                    style={{
                      padding: "3px",
                      lineHeight: "11px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Upgradeable
                  </Tag>
                </Tooltip>
              </div>
            );
          }}
        />
        <Column
          title="Type"
          dataIndex="cluster_type"
          key="cluster_type"
          width={1}
          render={(cluster_type, cluster) => {
            return (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "11px",
                  lineHeight: "12px",
                }}
              >
                {cluster.cluster_size}
                <br />
                {cluster_type.includes("CPU") ? "CPU" : ""}
                {cluster_type.includes("GPU") ? "GPU" : ""}
              </div>
            );
          }}
        />
        {label.toLowerCase() !== "dedicated" && (
          <Column
            title="Users"
            dataIndex="id"
            key="id"
            width={60}
            render={(id, record) => (
              <>
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={handleUsersClick(id)}
                >
                  {
                    assignments.filter(
                      (assignment) =>
                        assignment.cluster_id === id &&
                        assignment.service_level === record.service_level &&
                        assignment.status === "complete"
                    ).length
                  }
                </div>
              </>
            )}
          />
        )}
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          width={100}
          render={(status) => (
            <>
              {status === "online" ? (
                <Tag color="#87d068" style={statusStyle}>
                  {uppercaseFirst(status)}
                </Tag>
              ) : (
                <Tag color="#ff5500" style={statusStyle}>
                  {uppercaseFirst(status)}
                </Tag>
              )}
            </>
          )}
        />
        <Column
          title=""
          dataIndex="id"
          key="id"
          width={120}
          render={(id, record) => {
            const deleted = record.status === "deleted";
            return (
              <Space size={2} style={{ float: "right" }}>
                <Button
                  key="history"
                  onClick={handleHistoryClick(id)}
                  size="small"
                >
                  <HistoryOutlined />
                </Button>
                <Button
                  key="grafana"
                  onClick={handleGrafanaClick(record)}
                  size="small"
                >
                  <LineChartOutlined />
                </Button>
                <Button key="usage" onClick={handleUsageClick(id)} size="small">
                  <DollarOutlined />
                </Button>
                <Popconfirm
                  key="backup"
                  title={<>Are you sure you want to backup this cluster?</>}
                  onConfirm={handleBackupCluster(record)}
                  okText="Yes"
                  cancelText="No"
                  disabled={deleted}
                >
                  <Button size="small" disabled={deleted}>
                    <FileSyncOutlined />
                  </Button>
                </Popconfirm>
                <Button
                  key="kube_config"
                  onClick={handleKubeConfigClick(id)}
                  size="small"
                >
                  <ClusterOutlined />
                </Button>
                <Button
                  key="delete"
                  onClick={openClusterDeleteModal(record)}
                  disabled={record.service_level === SERVICE_LEVEL.FREE_SHARED}
                  style={{ margin: "0" }}
                  size="small"
                >
                  <DeleteOutlined />
                </Button>
              </Space>
            );
          }}
        />
      </Table>
    );
  };

  const getInstanceType = (cluster) => {
    if (cluster.cluster_type.includes("CPU")) {
      return "cpu";
    } else if (cluster.cluster_type.includes("GPU")) {
      return "gpu";
    }
    return "";
  };

  const dedicatedSizes = useMemo(() => {
    const dedicatedClusters = clusters.filter(
      (cluster) =>
        cluster.status !== "deleted" &&
        cluster.service_level === SERVICE_LEVEL.DEDICATED
    );
    return dedicatedClusters.reduce(
      (acc, cur) => {
        const instanceType = getInstanceType(cur);
        if (instanceType) {
          const instanceSize = cur.cluster_size;
          if (cur.owner_id) {
            acc[instanceType][instanceSize].used =
              acc[instanceType][instanceSize].used + 1;
          } else {
            acc[instanceType][instanceSize].free =
              acc[instanceType][instanceSize].free + 1;
          }
        }
        return acc;
      },
      {
        cpu: {
          XS: { used: 0, free: 0 },
          S: { used: 0, free: 0 },
          M: { used: 0, free: 0 },
          L: { used: 0, free: 0 },
        },
        gpu: {
          XS: { used: 0, free: 0 },
          S: { used: 0, free: 0 },
          M: { used: 0, free: 0 },
          L: { used: 0, free: 0 },
        },
      }
    );
  }, [clusters]);

  const statusStyle = {
    width: "70px",
    textAlign: "center",
    marginLeft: "8px",
  };

  const labelMap = {
    XS: "Extra Small",
    S: "Small",
    M: "Medium",
    L: "Large",
  };

  const sizeMap = {
    XS: "Small",
    S: "Large",
    M: "Large",
    L: "Large",
  };

  const colorSize = {
    XS: "xsmall",
    S: "small",
    M: "medium",
    L: "large",
  };

  return host_clusters.length > 0 ? (
    <div style={{ marginBottom: "20px" }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Input
          value={searchTerm}
          onChange={onSearch}
          addonAfter={<SearchOutlined />}
          placeholder="Enter search term"
          size="large"
        />
        {buildTable("Free", filteredClusters(SERVICE_LEVEL.FREE_SHARED))}
        <Divider dashed />
        {INSTANCE_TYPES.map((type) => {
          return (
            <Row key={type} gutter={16}>
              {Object.keys(dedicatedSizes[type]).map((size) => {
                return (
                  <Col
                    key={size}
                    span={24 / Object.keys(dedicatedSizes[type]).length}
                  >
                    <Card
                      title={
                        <h3
                          style={{
                            margin: 0,
                            color: `${SIZE_COLORS[colorSize[size]]}99`,
                            fontSize: "14px",
                          }}
                        >
                          {labelMap[size].replace("Extra", "X")}{" "}
                          <strong>{type.toUpperCase()}</strong>
                        </h3>
                      }
                      size="small"
                      extra={
                        <Button
                          onClick={(_) =>
                            create({
                              cluster_size: size,
                              cluster_type:
                                type.toUpperCase() === "GPU"
                                  ? `Small${type.toUpperCase()}`
                                  : `${sizeMap[size]}${type.toUpperCase()}`,
                              service_level: SERVICE_LEVEL.DEDICATED,
                            })()
                          }
                          style={{
                            color: `${SIZE_COLORS[colorSize[size]]}99`,
                            borderColor: `${SIZE_COLORS[colorSize[size]]}33`,
                          }}
                          size="small"
                        >
                          <PlusOutlined />
                        </Button>
                      }
                      style={{
                        border: `1px solid ${SIZE_COLORS[colorSize[size]]}22`,
                        borderRadius: 5,
                        marginBottom: 8,
                      }}
                      headStyle={{
                        backgroundColor: `${SIZE_COLORS[colorSize[size]]}11`,
                      }}
                      bodyStyle={{
                        backgroundColor: `${SIZE_COLORS[colorSize[size]]}09`,
                        padding: "10px 10px",
                      }}
                    >
                      <Row>
                        {Object.keys(dedicatedSizes[type][size]).map((stat) => {
                          return (
                            <Col
                              key={stat}
                              span={12}
                              style={{
                                textAlign: "center",
                                color: "#999999",
                                fontSize: 12,
                                fontWeight: 200,
                                padding: 0,
                              }}
                            >
                              {stat}
                            </Col>
                          );
                        })}
                      </Row>
                      <Row>
                        {Object.keys(dedicatedSizes[type][size]).map((stat) => {
                          return (
                            <Col
                              key={stat}
                              span={12}
                              style={{
                                textAlign: "center",
                                color:
                                  stat === "used"
                                    ? `${SIZE_COLORS[colorSize[size]]}99`
                                    : dedicatedSizes[type][size][stat] > 0
                                    ? "green"
                                    : undefined,
                                backgroundColor:
                                  stat === "used"
                                    ? `${SIZE_COLORS[colorSize[size]]}11`
                                    : dedicatedSizes[type][size][stat] > 0
                                    ? "#87d06855"
                                    : "#ffffff99",
                                padding: 0,
                              }}
                            >
                              {dedicatedSizes[type][size][stat]}
                            </Col>
                          );
                        })}
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          );
        })}
        <Divider style={{ margin: "12px 0" }} dashed />
        <Collapse defaultActiveKey={["active"]} bordered={false} ghost>
          <Panel header="Active" key="active">
            {buildTable("Dedicated", filteredClusters(SERVICE_LEVEL.DEDICATED))}
          </Panel>
          <Panel header="Deleted" key="deleted">
            {buildTable(
              "Dedicated",
              filteredClusters(SERVICE_LEVEL.DEDICATED, "deleted")
            )}
          </Panel>
        </Collapse>
      </Space>
      {showClusterUsersModal && clusterId && (
        <ClusterUsersModal
          id={clusterId}
          cluster={clusters.find((cluster) => cluster.id === clusterId)}
          users={users}
          assignments={assignments}
          enableAddUser={clusters.reduce((acc, cur) => {
            if (cur.service_level === SERVICE_LEVEL.FREE_SHARED) return true;
            return acc;
          }, false)}
          width={900}
          height={window.innerHeight - 200}
          visible={showClusterUsersModal}
          close={handleClusterUsersClose}
        />
      )}
      {showClusterUsageModal && clusterId && (
        <ClusterUsageModal
          id={clusterId}
          cluster={clusters.find((cluster) => cluster.id === clusterId)}
          usage={usage.filter((item) => item.cluster_id === clusterId)}
          width={window.innerWidth - 200}
          height={880}
          visible={showClusterUsageModal}
          close={handleClusterUsageClose}
        />
      )}
      {showClusterHistoryModal && clusterId && (
        <ClusterHistoryModal
          id={clusterId}
          cluster={clusters.find((cluster) => cluster.id === clusterId)}
          users={users}
          assignments={assignments}
          width={window.innerWidth - 200}
          height={880}
          visible={showClusterHistoryModal}
          close={handleClusterHistoryClose}
        />
      )}
      {deleteCluster && (
        <ClusterDeleteModal
          cluster={deleteCluster}
          onCancel={() => {
            setDeleteCluster(null);
          }}
          onDelete={() => {
            setDeleteCluster(null);
          }}
        />
      )}
    </div>
  ) : (
    <Empty
      description="No Clusters Found"
      style={{ marginTop: "40px" }}
    ></Empty>
  );
};

export default ClustersPane;
